<template>
  <div id="misc">
    <img
      class="misc-mask"
      height="226"
      :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
      alt="misc-mask"
    />

    <!-- <v-img class="misc-tree" src="@/assets/images/misc/tree-2.png" alt="misc-tree" /> -->

    <div class="page-title text-center px-5">
      <h2 class="text-2xl font-weight-semibold text--primary">
        {{ t('login.welcome') }} {{ appName }}!
      </h2>
      <p class="text-sm mt-4">
        {{ appDescription }}
      </p>

      <div class="misc-character d-flex justify-center">
        <v-img
          max-width="100"
          :src="appLogo"
          alt="misc-character"
        />
      </div>

      <!-- <v-btn
        color="secondary"
        to="/"
        class="mb-4"
      >
        {{ t('miscellaneous.back_to_home') }}
      </v-btn> -->
    </div>
  </div>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import themeConfig from '@themeConfig'

export default {
  setup() {
    const { t } = useUtils()

    return {
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      appDescription: themeConfig.app.description,

      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/misc.scss';
</style>
